/* grayscale, from darkest to lightest */
$black:           #000000;
$midnight:        #101518;
$inkwell:         #1d2228;
$batcave:         #232a31;
$ramones:         #2c363f;
$charcoal:        #464e56;
$battleship:      #5b636a;
$dolphin:         #6e7780;
$backwater:       #687078;
$shark:           #828a93;
$gandalf:         #979ea8;
$bob:             #b0b9c1;
$pebble:          #c7cdd2;
$dirty_seagull:   #e0e4e9;
$grey_hair:       #f0f3f5;
$marshmallow:     #f5f8fA;
$squirrel:        #6B7885;
$pigeon:          #5C6875;
$white:           #ffffff;

/* colors */
$watermelon:      #ff5257;
$solo_cup:        #eb0f29;
$malibu:          #ff0080;
$barney:          #cc008c;
$mimosa:          #ffd333;
$turmeric:        #ffa700;
$masala:          #ff8b12;
$cheetos:         #fd6100;
$carrot_juice:    #ff520d;
$mulah:           #1ac567;
$bonsai:          #008751;
$spirulina:       #00a0a0;
$sea_foam:        #11d3cd;
$sky:             #12a9ff;
$peeps:           #7dcbff;
$dory:            #0f69ff;
$scooter:         #0063eb;
$cobalt:          #003abc;
$denim:           #1a0dab;
$blurple:         #5d5eff;
$hendrix:         #f8f4ff;
$starfish:        #7759ff;
$thanos:          #907cff;
$hulk_pants:      #7e1fff;
$grape_jelly:     #6001d2;
$mulberry:        #5015b0;
$malbec:          #39007d;
$solitude:        #ECF0FE;
$gainsboro:       #E1E4E9;
$ghost_white:     #f5f7ff;

// Colors from figma may or may not be fuji?
$pineapple:        #ffcb00;
$peach_yellow:     #FEE716;
$picasso:          #0047ff;
$gotham:           #121619;
$owl:              #e1e5ea;
$alice_blue:       #ebf0ff;

// Not an official Fuji color, but in the figma for AOL
$dory-hover:        #0e5ee6;
$solo_cup_darker:   #cc031a;
$sea_foam_lighter:  #caf0f8;
$peeps_light:       #eaf1ff;
$peeps_lighter:     #f3f7ff;
$dory_darker:       #0040a0;
$dory_dark:         #1251f2;
$accent_yellow:     $pineapple;
$accent_blue:       $picasso;
$picasso_light:     #0047FF14;
$salsa:             #F9ECED;
$tomato:            #DA001A;
$guac:              #EDF5F1;
$avocado:           #00804D;
$honeydew:          #FEFFFE;
$snow:              #F7FAFC;
$rhino:             #263442;
$badger:            #18212B;
$owl:               #E1E5EA;
$koala:             #C2C9D2;
$gotham-dark:       #11151C;
$election-red:      #FF001E;
$solid_blue:        #0000FF;
$badger-dark:       #1C2833;
$dim-gray:          #6D6D6D;
$pale-orange:       #DCA55C;
$burgundy:          #511B38;
$surface-primary:   #F6F8FF;
$floral-white:      #F7F5F2;
$mushroom-white:    #F0E1CD;
$grayish-orange:    #F3EEE6;
$purple-dark:       #392F4D;

// olympic medal colors
$gold-medal:              #FFCB00;
$silver-medal:            #A9B1B9;
$bronze-medal:            #CF7F27;
$gold-medal-light:        #FDE48E;
$silver-medal-light:      #E1E5EA;
$bronze-medal-light:      #FBD59F;
$gold:                    #A68516;
$silver:                  #8594A5;
$bronze:                  #B16512;

// Rebrand Colors
$submarine-yellow: #FFD327;
$Running-Man-Yellow-25: #FFE993;

// Box Shadows
$fluffy-pancake: 0px 16px 20px 0px rgba(199, 205, 210, 0.40), 0px 0px 16px 0px rgba(0, 0, 0, 0.05);
$fluffy-pancake-alt: 0px 0px 16px 0px rgba(0, 0, 0, 0.05), 0px 16px 20px 0px rgba(199, 205, 210, 0.40);
$light-elevation: 0px 4px 8px 0px rgba(0, 0, 0, 0.1), 0px 0px 1px 0px rgba(0, 0, 0, 0.1);
$artboard: 20px 40px 70px 20px rgba(0, 0, 0, 0.05), -60px 40px 80px 40px rgba(0, 0, 0, 0.05);
$bop-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.10), 0px 4px 8px 0px rgba(0, 0, 0, 0.10);
$turbo-vote-dark: 0px 0px 2px 0px rgba(0, 0, 0, 0.95), 0px 4px 16px 0px rgba(0, 0, 0, 0.80);
$medicare-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.05), 0px 16px 20px 0px rgba(199, 205, 210, 0.4);

// Backgrounds Colors
$bluesky-gradient: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.03) 100%);
$turbo-vote-gradient: linear-gradient(180deg, #FFF 0%, #F0F3F5 100%);
$turbo-vote-btn-gradient: linear-gradient(90deg, #0047ff 0%, #ff0095 100%);

$bop-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 4.82%, #F1F5F9 96.42%);
$turbo-vote-btn-gradient-dark: linear-gradient(90deg, #44ABFF 0%, #FF4B4E 100%);
